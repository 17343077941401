'use client';

import { useCallback } from 'react';
import Swal from 'sweetalert2';

const VERSION = '2024-10-01';

const DownloadButton = () => {
  const handleClick = useCallback(() => {
    Swal.fire({
      title: '다운로드 시작!',
      html: `추가아이템 스킨 ${VERSION} 버전 다운로드가 시작됩니다.<br />혹시 스킨 설치/업데이트 방법을 모르신다면 설치 및 사용방법을 확인하세요.`,
      icon: 'success',
      showCloseButton: true,
      confirmButtonText: '설치 및 사용방법 보러가기',
    }).then((result) => {
      if (result.isConfirmed) {
        window.setTimeout(() => document.getElementById('install-link')?.click(), 450);
      }
    });
  }, []);
  return (
    <div className='text-center'>
      <a
        href={`/releases/${VERSION}/moreitem.zip`}
        className='inline-block w-full rounded-xl bg-[#26d] py-5 text-xl font-semibold text-white decoration-0 hover:bg-[#49f]'
        download={`[${VERSION}] 추가아이템 스킨.zip`}
        onClick={handleClick}
        rel='nofollow'
      >
        추가아이템 스킨
        <br />
        최신버전 다운로드
        <br />({VERSION})
      </a>
      <br />
      <a
        href={`/releases/${VERSION}/moreitem_en.zip`}
        className='mt-2 inline-block text-[#26d] decoration-0 hover:underline'
        download={`[${VERSION}] 영문판 추가아이템 스킨.zip`}
        onClick={handleClick}
        rel='nofollow'
      >
        영문판 추가아이템 스킨 다운로드
      </a>
    </div>
  );
};
export default DownloadButton;
